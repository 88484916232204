<template>
  <div>
    <section class="section">
      <div class="section-center">
        <div class="columntitle">
          <span> 新闻动态 </span>
          <span> Journalism </span>
          <span>
            <img src="../../assets/image/enterpriseNews/lineimg.png" alt="" />
          </span>
        </div>
        <div class="subtitle">
          <p>开讲啦！直击华为北京机器视觉好望学堂第三期</p>
          <p>发表时间:2021-06-25 15:55</p>
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/lecture/img1.png"
              alt=""
            />
          </div>
        </div>
        <div class="describe">
          <div class="bg">
            <span>
              6月23日，华为北京机器视觉好望学堂第三期在北京市丰台区盈坤世纪D座8楼有为信通展厅举行，举办学堂的主要目的是介绍有为信通公司、推广华为好望的优质产品，以及分享相关经验。众多华为合作伙伴同仁同聚一堂，交流学习，共谋发展！
            </span>
          </div>
        </div>

        <div class="imgbox">
          <div class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/lecture/img2.png"
              alt=""
            />
          </div>
        </div>
        <div class="describe">
          <div style="line-height: normal" class="bg">
            <span>
              本期课程主要涉及有为信通公司及产品介绍、华为智慧零售解决方案、好望解决方案等方面。匹配客户痛点而推出的解决方案，受到现场人员较高关注度。
            </span>
          </div>
        </div>
        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/lecture/bg-title1.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div style="margin-bottom: 20px" class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/lecture/img3.png"
              alt=""
            />
          </div>
        </div>
        <div class="bg-title">
          <img
            src="../../assets/image/enterpriseNews/lecture/bg-title2.png"
            alt=""
          />
        </div>
        <div class="imgbox">
          <div style="margin-bottom:0px" class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/lecture/img4.png"
              alt=""
            />
            <div style="margin-bottom: 20px" class="img-title">
              有为信通愿与华为合作伙伴同仁一道努力，推出更加专业的解决方案，切实解决客户痛点，创造更大价值！
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  .section-center {
    padding-top: 50px;
    font-size: 18px;
    color: #000;
    max-width: 1466px;
    margin: 0 auto;
    .columntitle {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      color: #6f36eb;
      text-align: left;
      margin-bottom: 40px;
      span {
        font-size: 30px;
        display: inline-block;
      }
      span:nth-child(2) {
        margin: 0 10px 0 14px;
        font-size: 28px;
        color: #ccc;
      }
      span:nth-child(3) {
        img {
          width: 100%;
        }
        max-width: 1160px;
      }
    }
    .subtitle {
      font-family: IOS9W4;
      font-weight: 400;
      padding: 22px 0 20px 24px;
      text-align: left;
      background-color: #f5f5f5;
      p:nth-child(1) {
        height: 30px;
        font-size: 30px;
        color: #333333;
        margin-bottom: 14px;
      }
      p:nth-child(2) {
        height: 20px;
        font-size: 20px;
        color: #999;
      }
    }
    .video-box {
      height: 500px;
      .video {
        width: 900px;
        height: 100%;
      }
    }
    .describe {
      border: 1px dashed #aad4ee;
      border-radius: 4px;
      padding: 4px;
      .bg {
        border-radius: 4px;
        background-color: #f4effd;
        color: #8735e4;
        font-size: 18px;
        padding: 40px 48px 38px 48px;
        line-height: 60px;
        text-align: left;
      }
      // background-color: ;
    }
    .textdescribe {
      text-align: left;
      line-height: 60px;
    }
    .describe1 {
      height: 120px;
      .bg {
        height: 80px;
      }
    }
    .imgbox {
      .imgbox-center {
        margin: 64px auto;
        max-width: 900px;
        .img-title {
          height: 20px;
          color: #333;
        }
        img {
          margin-bottom: 40px;
          width: 100%;
        }
        img:last-child {
          margin: 0 !important;
        }
        // img:nth-child(3) {
        //   margin: 0;
        // }
      }
    }
    .bg-title {
      height: 28px;
      margin: 50px 0 50px 0;
      img {
        width: 100%;
      }
    }
    .bg-subtitle {
      height: 20px;
      margin-bottom: 40px;
      color: #333;
    }
  }
}
</style>